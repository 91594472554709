export default function useScrollLock(id: string) {
  const lockedIds = useState('lockedIds', () => new Set<string>());

  const isLocked = (): boolean => lockedIds.value.size > 0;

  useHead({
    bodyAttrs: {
      class: computed(() => ({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'no-scroll': isLocked(),
      })),
    },
  });

  return {
    lock: (): void => {
      lockedIds.value.add(id);
    },
    unlock: (): void => {
      lockedIds.value.delete(id);
    },
    isLocked,
  };
}
